
import axios from 'axios';

let baseUrl = 'https://api.acordofechado.com.br/dev-clients';
if ( window.location.href.includes( 'https://console.acordofechado.com.br' ) ) {
  baseUrl = 'https://api.acordofechado.com.br/clients';
}

const clientsApi = axios.create( {
  baseURL: baseUrl
} );

clientsApi.interceptors.request.use( async config => {
  const token = localStorage.getItem( 'af-token' );
  if ( token ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
} );

export default clientsApi;
