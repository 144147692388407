import React from 'react';
import { CallActionsContainer } from './styles';
import MuteBtn from './MuteBtn';
import CallHandlerBtn from './CallHandlerBtn';
import PauseBtn from './PauseBtn';
import { useDialerContext } from '../DialerContext';

const CallActions = () => {
	const {
		handleCall,
		callNumber,
		handleHangup,
		callInProgress,
	} = useDialerContext();

	if ( callInProgress ) {
		return (
			<CallActionsContainer>
				<MuteBtn />
				<CallHandlerBtn 
					handleCall={() => handleCall( callNumber )} 
					disableColor={false}
					handleHangup={handleHangup}
					sessionInProgress={callInProgress}
				/>
				<PauseBtn />
			</CallActionsContainer>
		);
	}

	return (
		<CallActionsContainer>
			<CallHandlerBtn 
				handleCall={() => handleCall( callNumber )} 
				disableColor={callNumber?.length === 15}
				handleHangup={handleHangup}
				sessionInProgress={callInProgress}
			/>
		</CallActionsContainer>
	);
};

export default CallActions;