import axios from 'axios';

const workflowApi = axios.create( {
  baseURL: 'https://api.acordofechado.com.br/actions'
} );

workflowApi.interceptors.request.use( async config => {
  const token = localStorage.getItem( 'af-token' );
  if ( token ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
} );

export default workflowApi;
