import React from 'react';
import { GreenSwitch } from './styles';

const DialerStatusButton = ( { isConnected, onDesactivate, onActivate } ) => {
	return (
		<GreenSwitch 
			defaultChecked={isConnected} 
			value={isConnected} 
			onChange={isConnected ? onDesactivate : onActivate} 
		/>
	);
};

export default DialerStatusButton;