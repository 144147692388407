import jwtDecode from 'jwt-decode';

export function isAdmin() {
	const jwtDecoded = jwtDecode( localStorage.getItem( 'af-token' ) );
	return jwtDecoded.tenant_id === 3;
}

export function abstractDataUser() {
	if ( localStorage.getItem( 'af-token' ) === null ) {
		return {
			userId      : null,
			tenantId    : null,
			profileType : null,
			default_url : null,
		};
	}

	const jwtDecoded = jwtDecode( localStorage.getItem( 'af-token' ) );

	return {
		userId      : jwtDecoded.userId,
		tenantId    : jwtDecoded.tenant_id,
		profileType : jwtDecoded.idProfileType,
		default_url : jwtDecoded?.default_url ?? '',
	};

}

export function getExperIpCredentials() {
	const afToken = localStorage.getItem( 'af-token' );
	if ( afToken ) {
		const jwtDecoded = jwtDecode( afToken );
		return {
			voip_user     : jwtDecoded?.voip_user,
			voip_password : jwtDecoded?.voip_password,
		};
	}
}

export function validateExperIpLogin() {
	const afToken = localStorage.getItem( 'af-token' );
	if ( afToken ) {
		const jwtDecoded = jwtDecode( afToken );
		const validUser = jwtDecoded?.voip_user !== '' && jwtDecoded?.voip_user;
		const validPassword = jwtDecoded?.voip_password !== '' && jwtDecoded?.voip_password;
		if ( validUser && validPassword ) {
			return true;
		}
		return false;
	}
}

export function getTenantId() {
	if ( localStorage.getItem( 'af-token' ) === null ) {
		return null;
	}

	const jwtDecoded = jwtDecode( localStorage.getItem( 'af-token' ) );
	return jwtDecoded.tenant_id;
}

export function getUserId() {
	const jwtDecoded = jwtDecode( localStorage.getItem( 'af-token' ) );
	return jwtDecoded.userId;
}

export function getProfileType() {
	if ( localStorage.getItem( 'af-token' ) === null ) {
		return null;
	}
	const jwtDecoded = jwtDecode( localStorage.getItem( 'af-token' ) );
	return jwtDecoded.idProfileType;
}
