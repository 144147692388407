// import axios from "axios";
import {
	CASES_FIND,
	// CASES_REGISTER,
	// CASES_REMOVE,
	CASES_MASS_EDIT,
	// DEALS_FIND,
	// DEALS_REMOVE,
	// DEALS_REGISTER,
	BULK_VALIDATE,
	BULK_REGISTER,
	// BULK_FIND,
	DEALS_CONSTANTS,
	DEALS_UPDATE,
	NEW_BULK_REGISTER,
	CASES_ZAPSIGN,
	CASES_SIGNED_DRAFT,
	NEW_BULK_CHECK
} from 'constants/Links';
import { progressActions } from 'actions/Setting';
import { store } from '../MainApp';
import { bulkCaseApi, api, casesApi, casesIdsApi } from './createApi';

const cases = {
	casesReport: async function( payload ) {
		const response = await casesApi.post( '/fetch-cases', payload );
		return response;
	},
	findCasesId: async function( payload ) {
		try {
			return await casesIdsApi
				.post( '/get-cases-ids', payload )
				.then( response => response )
				.finally( () => {
					store.dispatch( progressActions( { percent: false, message: '' } ) );
				} )
				.catch( () => {
					store.dispatch( { type: 'MODAL_LOTE', payload: false } );
				} );
		} catch ( error ) {
			console.error( 'errorReportBuild.catch', error );
		}
	},

	find: async function( payload ) {
		const url = payload ? CASES_FIND + payload : CASES_FIND;
		try {
			const response = await api.get( url );

			return response;
		} catch ( err ) {
			if ( err.response && err.response.data.error === 'unauthorized' ) {
				localStorage.removeItem( 'af-token' );
				localStorage.removeItem( 'user_id' );
				window.location.href = '/signin';
			}
		}
	},

	massEdit: async function( payload ) {
		try {
			const response = await api.put( CASES_MASS_EDIT, payload );
			return response;
		} catch ( err ) {
			if ( err.response.data.error === 'unauthorized' ) {
				localStorage.removeItem( 'af-token' );
				localStorage.removeItem( 'user_id' );
				window.location.href = '/signin';
			}
		}
	},
	zapsign: async function( payload ) {
		try {
			return await api.put( CASES_ZAPSIGN.replace( ':caseId', payload.caseId ), { ...payload } );
		} catch ( error ) {
			return error.response;
		}
	},
	signedDraft: async function( payload ) {
		try {
			return await api.put( CASES_SIGNED_DRAFT.replace( ':caseId', payload.caseId ), { ...payload } );
		} catch ( error ) {
			return error.response;
		}
	}
};

const deals = {
	update: async function( payload ) {
		try {
			const response = await api.put( DEALS_UPDATE, payload );
			return response;
		} catch ( err ) {
			if ( err.response.data.error === 'unauthorized' ) {
				localStorage.removeItem( 'af-token' );
				localStorage.removeItem( 'user_id' );
				window.location.href = '/signin';
			}
		}
	},
	findConstant: async function() {
		try {
			const response = await api.get( DEALS_CONSTANTS );
			return response;
		} catch ( err ) {
			if ( err.response.data.error === 'unauthorized' ) {
				localStorage.removeItem( 'af-token' );
				localStorage.removeItem( 'user_id' );
				window.location.href = '/signin';
			}
		}
	}
};

const bulk = {
	validate: async ( payload ) => {
		return await bulkCaseApi.post( BULK_VALIDATE, payload );
	},

	check: async( payload, cancelToken = '' ) => {
		return await bulkCaseApi.get( NEW_BULK_CHECK.replace( ':id', payload ), { cancelToken: cancelToken.token } );
	},

	register: async ( payload ) => {
		return await bulkCaseApi.post( BULK_REGISTER, payload );
	},
	newRegister: async ( payload ) => {
		return await bulkCaseApi.post( NEW_BULK_REGISTER, payload );
	}
};

export { cases, deals, bulk };
